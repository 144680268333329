import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import Slider from 'react-slick'
import SEO from '../components/seo'

const Heading = styled.h1`
  margin-bottom: 0.25em;
`
const Subheading = styled.p`
  margin-bottom: 2em;
`
const SingleCityServices = styled.div`
  flex: 0 0 25%;
  @media (max-width: 992px) {
    flex: 0 0 50%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
  margin-bottom: 2em;
  a {
    color: #666;
    font-size: 0.9em;
  }
  h3 {
    a {
      color: #333;
    }
    margin-bottom: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 1;
  }
`

const ServicesList = (props) => {
  let { slug, location, locationslug, serviceTitle } = props
  return (
    <li>
      <Link to={'/' + slug + '-' + locationslug + '/'} title={serviceTitle}>
        {serviceTitle}
      </Link>
    </li>
  )
}

const CitiesServicesList = (props) => {
  let { location, slug, services, marketSegment = [] } = props

  return (
    <SingleCityServices>
      <h3>{location}</h3>
      <ul>
        {services.edges.map((item, i) => {
          return marketSegment.map((marketSeg) => {
            if (
              [
                'kitchen-remodeling/',
                'kitchen-cabinets/',
                'bathroom-remodeling/',
              ].includes(item.node.slug) &&
              marketSeg !== 'sac'
            ) {
              return null
            }
            let cleanReplaceServiceTitle = item.node.serviceTitle.replace(
              /{{city}}/g,
              location
            )
            return (
              <ServicesList
                key={item.node.id}
                locationslug={slug}
                location={location}
                serviceTitle={cleanReplaceServiceTitle}
                slug={item.node.slug}
              />
            )
          })
        })}
      </ul>
    </SingleCityServices>
  )
}

const ServiceArea = (props) => (
  <StaticQuery
    query={graphql`
      query getCitiesList {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              location
              slug
              marketSegment
            }
          }
        }
        allContentfulServiceAreaContent {
          edges {
            node {
              id
              slug
              serviceTitle
              content {
                id
                content
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Layout pageProps={props}>
          <SEO
            title={'Service Areas | HouseIdea'}
            description={
              'This page contains a list of our service areas. If you do not see your city, please call us to confirm if you are in our service area.'
            }
          />
          <div className="container brand-padding-y">
            <Heading>Service Areas</Heading>
            <Subheading>
              If you want a custom home builder that offers high-quality
              products and first-class craftmanship for your project, choose
              HouseIdea! Learn more about our premium services in the following
              areas:
            </Subheading>
            <div className="d-flex flex-wrap">
              {data.allContentfulServiceArea.edges.map((item, i) => {
                return (
                  <CitiesServicesList
                    key={item.node.id}
                    location={item.node.location}
                    slug={item.node.slug}
                    marketSegment={item.node.marketSegment}
                    services={data.allContentfulServiceAreaContent}
                  />
                )
              })}
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default ServiceArea
